// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.text {
  display: inline-flex;
  min-height: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  strong {
    display: block;
  }
}

.radioContainer {
  flex-wrap: wrap;
}

.insetText {
  display: none;
  flex: 0 0 100%;
}

.radio {
  &:checked + .insetText {
    display: block;
  }
}

// sass-lint:disable class-name-format property-sort-order no-color-literals no-empty-rulesets
.wmnds-fe-input-datepicker {
  background: url('./calendar.svg') no-repeat scroll center right 10px;
  padding-right: 30px;
  max-width: 309px;
}

.disruptions-date-picker {
  border-radius: 0.1rem;
  border: 0;
  box-shadow: 0 0 5px 0 rgba(192, 192, 192, 0.5);
  max-width: 100%;
  margin-top: 1rem;

  @media screen and (min-width: 340px) {
    padding: 0 16px 10px;
  }
}

// Date Picker - Weekends Background Colours
.react-datepicker {
  &__header {
    background: none;
    border: 0;
  }

  // DAY STYLES
  &__day,
  &__day-name {
    padding: 8px;
    width: 38px;
    height: 38px;
    margin: 0;
    line-height: 1.2;
  }

  &__day-name {
    border-bottom: solid 1px #979797;
  }

  &__day--weekend {
    position: relative;
    border: 0;
    z-index: 1;
    background-color: #f5f5f5;
  }

  // Active
  &__day--selected,
  &__day:hover {
    border-radius: 50px;
    background-color: #e2cee7;
    color: #333333;
  }

  // Today State
  &__day--today {
    font-weight: normal;
  }

  // Bold the select date font
  &__day--selected {
    font-weight: bold;
  }

  // WEEK STYLES
  &__week {
    padding: 0;
    margin: -1px;
  }

  // MONTH STYLES
  &__current-month {
    font-size: 18px;
    margin: 20px 0;
  }

  // Prev & Next Icons
  &__navigation {
    top: 25px;

    &:hover *::before {
      border-color: transparent;
      border-right: 5px solid #000000;
    }

    &-icon {
      &::before {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #000000;
      }

      &--previous::before {
        transform: rotate(0deg);
      }

      &--next::before {
        transform: rotate(180deg);
      }
    }
  }
}

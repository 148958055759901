// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.fileUploadFeGroup {
  margin-bottom: $size-lg;
}

.fileUpload {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}

.fileUploadPreview {
  max-height: 333px;
}

.fileUploadLabelFocused {
  outline: 0;
  background-color: get-color(primary);
  box-shadow: 0 0 0 2px $white, 0 0 0 4px get-color(secondary);
}

.fileUploadUploaded {
  display: flex;
  align-items: center;

  button {
    flex-shrink: 0;
  }
}

// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.card {
  margin-right: -$size-sm;
  margin-left: -$size-sm;
  padding: $size-md;
  @media screen and (min-width: $breakpoint-md) {
    margin-right: 0;
    margin-left: 0;
    padding: $size-lg;
  }
}

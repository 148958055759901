// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.iconRight {
  display: inline-flex !important;
  min-height: 50px;
  padding-top: $size-xs;
  padding-bottom: $size-xs;
  text-align: left;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  svg[class$='icon--right'] {
    float: none;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 22px;
  }
}
